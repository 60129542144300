import React from 'react'
import { graphql } from 'gatsby'


const ImgPost = ({ data }) => {
    const treatmentsPost = data.markdownRemark

    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: treatmentsPost.html }}></div>
        </>
    )
}

export const query = graphql`
    query Img($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            frontmatter {
                img {
                                    childImageSharp {
                                        fluid(quality: 100) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
            }
            html
        }
    }
`

export default ImgPost